<template>
    <main class="main" v-if="!loading">
      <section class="section-box">
        <div class="banner-hero banner-head-image"  v-bind:style="{ backgroundImage: 'url(' + entity.background_url + ')' }">
          <div class="container">
            <div class="text-center"><img :src="entity.logo_url" style="max-width:300px;"/>
              <h1 class="text-heading-1 color-white mt-30">{{entity.name}}</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="section-box mt-50 mb-50">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="single-detail mt-0">
			  <h3 class="text-heading-4 mb-20">{{entity.name}}</h3>
			  <p>{{entity.complement.description}}</p>
			  </div>
            </div>
          </div>
          <!--<div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-12">ici</div>
              </div>
            </div>
          </div>-->
		  
		<div class="row mt-40" v-if="children && (children.length > 0)">
            <div v-for="commune in children" v-bind:key="commune.id" class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="product-item-2 hover-up"><a :href="'/commune/'+commune.id">
                  <div class="product-image"><img :src="commune.background_url" alt="Ypodo" style="max-width:100%;"></div></a>
                <div class="product-info"><a :href="'/commune/'+commune.id">
                    <h3 class="text-body-lead color-gray-900">{{commune.name}}</h3></a>
                  <div class="rating mt-10">
				 <span>{{ truncte (commune.slogan, 90, '...')}}</span>
                  </div>
                  <div class="d-flex mt-20">
                    <div class="button-add text-end"><a class="btn btn-cart" :href="'/commune/'+commune.id">Découvrir</a></div>
                  </div>
                </div>
              </div>
            </div>
		</div>
          <div class="row" v-if="entity.posts && (entity.posts.length > 0)">
            <div class="col-lg-12">
              <div class="single-detail mt-30">
			  <h3 class="text-heading-4 mb-0">Derniers articles publiés</h3>
			  </div>
            </div>
          </div>		  

	
			<!-- begin listing-->
			<div class="row"  v-if="entity.posts && (entity.posts.length > 0)">
			<!-- begin element repetitif-->
			<div class="col-lg-12 col-sm-12 col-12" v-for="(post, index) in entity.posts">
			<div class="swiper-slide mt-40" style="width: 100%">
				<div class="block-news new">
                    <div class="item-news">
                      <div class="row">
                        <div class="col-xl-6 col-lg-12">
							<a href="#">
							<div v-if="post.image_url" class="img-news bg-image" v-bind:style="{ 'background-image': 'url(' + post.image_url + ')' }"></div>
							<div v-else class="img-news bg-image" v-bind:style="{ 'background-image': 'url(' + entity.background_url + ')' }"></div>
							</a>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="right-news card-grid-style-4 mb-20"><span class="tag-dot">{{post.postcategory.name}}</span>
						  <a class="text-heading-4" href="#">{{post.name}}</a>
                            <p class="text-body-text color-gray-500 text-desc-fix-h">{{post.introduction}}</p>
                            <div class="blog-img-user">
                              <div class="img-user img-user-round"><img :src="entity.logo_url" alt="Ypodo"></div>
                              <h4 class="text-heading-6 color-gray-900">{{entity.name}}</h4>
                              <p class="text-body-small color-gray-500">{{ post.created_at | moment('DD/MM/YYYY')}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
			</div>
			<!-- end element repetitif-->		  
			</div>
			<!-- end listing-->		  
	
			<!-- begin listing-->
			<div class="row"  v-if="feeds && (feeds.length > 0)">
			<!-- begin element repetitif-->
			<div class="col-lg-12 col-sm-12 col-12" v-for="(feed, index) in feeds">
			<div class="swiper-slide mt-40" style="width: 100%">
				<div class="block-news">
                    <div class="item-news">
                      <div class="row">
                        <div class="col-xl-6 col-lg-12">
							<a href="#">
							<div v-if="feed.image" class="img-news bg-image" v-bind:style="{ 'background-image': 'url(' + feed.image + ')' }"></div>
							<div v-else class="img-news bg-image" v-bind:style="{ 'background-image': 'url(' + entity.background_url + ')' }"></div>
							</a>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="right-news card-grid-style-4 mb-20"><span class="tag-dot">{{feed.category.term}}</span>
						  <a class="text-heading-4" href="#">{{feed.title}}</a>
                            <p class="text-body-text color-gray-500 text-desc-fix-h" v-html="removeTags(feed.description)">
							<br>
							<a target="_blank" :href="feed.guid" class="pt-2 d-block color-theme opacity-30">{{feed.guid}}</a>
							</p>
                            <div class="blog-img-user">
                              <div class="img-user img-user-round"><img :src="entity.logo_url" alt="Ypodo"></div>
                              <h4 class="text-heading-6 color-gray-900">{{entity.name}}</h4>
                              <p class="text-body-small color-gray-500">{{ feed.pub_date | moment('DD/MM/YYYY hh:mm:ss')}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
			</div>
			<!-- end element repetitif-->		  
			</div>
			<!-- end listing-->	


	
		  
		  <div class="row">
            <div class="col-lg-12">
              <div class="single-detail mt-50">
			  <div class="responsive-iframe max-iframe"><iframe  style="overflow:visible;min-height:400px;" :src="'https://maps.google.com/?ie=UTF8&ll='+entity.latitude+','+entity.longitude+'&spn=0.006186,0.016512&t=h&z=14&output=embed'" frameborder='0' allowfullscreen></iframe></div>
			  </div>
			</div>
		</div>
		  
		  
          <!-- <div class="row">
            <div class="col-lg-12">
              <div class="single-detail mt-40" style="display:block;">
			  <h3 class="text-heading-4 mb-20">{{entity.name}}</h3>
			  		  <table class="table mt-40">
                        <tbody>
                          <tr class="stand-up">
                            <th>Stand Up</th>
                            <td>
                              <p>35″L x 24″W x 37-45″H(front to back wheel)</p>
                            </td>
                          </tr>
                          <tr class="folded-wo-wheels">
                            <th>Folded (w/o wheels)</th>
                            <td>
                              <p>32.5″L x 18.5″W x 16.5″H</p>
                            </td>
                          </tr>
                          <tr class="folded-w-wheels">
                            <th>Folded (w/ wheels)</th>
                            <td>
                              <p>32.5″L x 24″W x 18.5″H</p>
                            </td>
                          </tr>
                          <tr class="door-pass-through">
                            <th>Door Pass Through</th>
                            <td>
                              <p>24</p>
                            </td>
                          </tr>
                          <tr class="frame">
                            <th>Frame</th>
                            <td>
                              <p>Aluminum</p>
                            </td>
                          </tr>
                          <tr class="weight-wo-wheels">
                            <th>Weight (w/o wheels)</th>
                            <td>
                              <p>20 LBS</p>
                            </td>
                          </tr>
                          <tr class="weight-capacity">
                            <th>Weight Capacity</th>
                            <td>
                              <p>60 LBS</p>
                            </td>
                          </tr>
                          <tr class="width">
                            <th>Width</th>
                            <td>
                              <p>24″</p>
                            </td>
                          </tr>
                          <tr class="handle-height-ground-to-handle">
                            <th>Handle height (ground to handle)</th>
                            <td>
                              <p>37-45″</p>
                            </td>
                          </tr>
                          <tr class="wheels">
                            <th>Wheels</th>
                            <td>
                              <p>12″ air / wide track slick tread</p>
                            </td>
                          </tr>
                          <tr class="seat-back-height">
                            <th>Seat back height</th>
                            <td>
                              <p>21.5″</p>
                            </td>
                          </tr>
                          <tr class="head-room-inside-canopy">
                            <th>Head room (inside canopy)</th>
                            <td>
                              <p>25″</p>
                            </td>
                          </tr>
                          <tr class="pa_color">
                            <th>Color</th>
                            <td>
                              <p>Black, Blue, Red, White</p>
                            </td>
                          </tr>
                          <tr class="pa_size">
                            <th>Size</th>
                            <td>
                              <p>M, S</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
			  </div>
            </div>
          </div>	-->	  
		  
		  

          
        </div>
      </section>	  
</main>
</template>

<script>
import AppConstants from '@/config'
export default {
  name: 'Commune',
  data() {
    return{
	loading: false,
	entity: {},
	children:[],
	feeds:[],
	posts:[],
	ititle:'',
	icontent:'',
	iimage: '',
	confirmation:'',
	p1:1,
	p2:1,
	p3:''
	}
  },
  components: {
  },
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    isloggedin: {
      type: Number,
      default: 0
    },
	isgeolocalized: {
      type: Number,
      default: 0
    },	
  },  
  created() {
     this.getEntity()
  },  
  mounted() {
	this.alert()
  }, 
	unmounted() {
     if (this.entity == {}) this.getEntity()
  },  
  methods: {
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	}, 
   removeTags(str) {
      if ((str===null) || (str===''))
      return str;
      else
      str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
   },	
	alert() {
		this.$emit('alert', this.id)
	},  
	readMorePost(post) {
		this.ititle = post.name
		this.icontent = post.description 
		this.iimage = post.image_url
		if (typeof post.guid !== 'undefined') {
			if (post.guid) {
			if (post.guid.length > 0) {
				var a = '<a class="btn bg-green-dark-sdw color-white btn-full btn-m rounded-s text-uppercase font-900 mr-0 ml-0 mt-2 mb-3" target="_blank" href="'+post.guid+'">Accéder à l\'article</a>'
				this.icontent = this.icontent + '<br>'+ a
				this.iimage = ''
			}
			}
		}
				
		
		this.openMenu('instant-1')
	},
	openLogin(){
		this.$emit('openlogin', '1')
	},	
	readMoreEntity() {
		this.ititle ='À propos de '+ this.entity.name
		this.icontent = this.entity.complement.description
		this.iimage = this.entity.background_url
		this.openMenu('instant-1')
	},	
	openMenu(id) {
		document.getElementById(id).classList.add("menu-active");
		document.getElementById(id).style.opacity = '1';
		document.getElementById(id).style.bottom =  "20px";		
	},	
	scroll(id) {
      const element = document.getElementById(id);
      if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
		} else {
			const element2 = document.getElementById(id+'2');
			element2.scrollIntoView({ behavior: 'smooth' });
		}
	
	},  
	showNotification(id){
		document.getElementById(id).classList.remove("hide");
		document.getElementById(id).classList.add("show");
	
	},
	closeNotification(id){
		document.getElementById(id).classList.remove("show");
		document.getElementById(id).classList.add("hide");
	
	},	
	addToFavorit() {
		if (localStorage.getItem('p1') !== null) {
					this.p1 = localStorage.getItem('p1');
		}
		if (localStorage.getItem('p2') !== null) {
					this.p2 = localStorage.getItem('p2');
		}
		if (localStorage.getItem('p3') !== null) {
					this.p3 = localStorage.getItem('p3');
		}
		var hasCommune = this.p3.indexOf(this.entity.id) != -1;
		if (hasCommune) {
			this.showNotification('notification-5')
			return false;
		} else {
			this.p3 = this.p3 + ','+this.entity.id
			this.p1 = this.p1 + ','+this.entity.id
		}
		var token = this.getConst('API_TOKEN');
		var id = this.getConst('API_ID');
		var apiUrl = this.getConst('API_URL')+"update-preferences"	
		var api_token = localStorage.getItem('token')
		let axios = require('axios');
		if (api_token) {
				
					var apiData = {
					"p1" : this.p1,
					"p2" : this.p2,
					"p3" : this.p3,
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						if (response.data.message =='OK') {
							localStorage.setItem('p1', this.p1)
							localStorage.setItem('p2', this.p2)
							localStorage.setItem('p3', this.p3)
							this.showNotification('notification-5')
						}
					})
		}	
	
	
	},
	getEntity() {
				this.loading = true
				var token = this.getConst('API_TOKEN')
				var id = this.getConst('API_ID')
				var apiUrl = this.getConst('API_URL')+"anonymous-entity"				
				
                if(this.id != 0) {
                    var apiData = { 
					"entity": this.id,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
						this.entity = response.data.data
						this.feeds = response.data.feeds
						this.ititle ='À propos de '+ this.entity.name
						this.icontent = this.entity.complement.description
						this.iimage = this.entity.background_url
						this.confirmation = this.entity.name+' a bien été ajouté à vos communes préférées !'
						if (this.entity.parent == 0) {
							this.children = this.entity.children
						}
						this.loading = false
					}
				})
				.catch(error => {
				console.log(error.message);
				})	
				} 
	},	
	setDestination(d){
		localStorage.setItem('destination', d);
	},	
	truncte (text, stop, clamp) {
		return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
	},	
  }
}


</script>
<style>
</style>
